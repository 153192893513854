/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  
  /* Modal container */
  .modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 90%;
    /* background: white; */
    border-radius: 8px;
    overflow: hidden;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Modal content */
  .modal-content {
    display: flex;
    flex-direction: column;
    /* height: 80vh; */
    max-height: 80vh;
    overflow: hidden;
  }
  
  /* Modal header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
    background-image: url('../../assets/images/dark_bg.png');
    background-size: cover;
    align-items: center;
  }
  
  .modal-header h3 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    color: #fff;
  }
  
  /* Modal body */
  .modal-body {
    flex: 1;
    padding: 36px;
    overflow-y: auto;
    /* background: #fafafa; */
    background-image: url('../../assets/images/dark_bg.png');
    background-size: cover;
    padding: 2rem !important;
  }
  
  .photo-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
  
  .photo-item {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  /* Loading text */
  .loading-text {
    text-align: center;
    margin-top: 16px;
    font-size: 1em;
    color: #888;
  }
  
  /* Modal footer */
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background: #f4f4f4;
    border-top: 1px solid #ddd;
  }
  
  .close-button-footer {
    background: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .close-button-footer:hover {
    background: #e60000;
  }
  